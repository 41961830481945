<template>
    <div>
        <b-container>
            <section class="admin">
                <div class="sector">
                    <AdminNavigation />
                    <h2 class="main-title">Manage users</h2>
                    <div v-if="!getUsersListPending" class="mb-3">
                        <Spinner size="medium" line-fg-color="#e91e63" />
                    </div>
                    <div v-else class="admin__box">
                        <div class="admin__table">
                            <div class="input-box">
                                <div v-if="filterUsers" @click.prevent="clearFilter" class="clear-icon clear-icon--filter">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <input v-model="filterUsers" class="custom-search-bar" type="text" placeholder="Filter" />
                            </div>
                            <b-table
                                hover
                                id="pendingUsers"
                                :items="getSortedUsersList"
                                :fields="fields"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :per-page="perPage"
                                :current-page="Number(currentPage)"
                                :filter="filterUsers"
                                responsive>
                                <template slot="sponsorType" slot-scope="data">
                                    <span v-if="data.item.sponsorType == 1">Free</span>
                                    <span v-else-if="data.item.sponsorType == 2">Standard</span>
                                    <span v-else-if="data.item.sponsorType == 3">Premium</span>
                                    <span v-else-if="data.item.sponsorType == 4">Ultimate</span>
                                </template>
                                <template slot="subscriptionStatus" slot-scope="data">
                                    <span v-if="data.item.subscriptionStatus == 1" class="profile__status profile__status--positive">Active</span>
                                    <span v-else-if="data.item.subscriptionStatus == 2" class="profile__status profile__status--pending">Pending</span>
                                    <span v-else-if="data.item.subscriptionStatus == 3" class="profile__status profile__status--negative">Inactive</span>
                                    <span v-else-if="data.item.subscriptionStatus == 4" class="profile__status profile__status--cancelled">Cancelled</span>
                                    <span v-else class="profile__status profile__status--neutral">None</span>
                                    <hr v-if="data.item.valid">
                                    <span v-if="data.item.valid"><strong>Valid until</strong><br>{{ data.item.valid }}</span>
                                    <hr v-if="data.item.next">
                                    <span v-if="data.item.next"><strong>Next payment</strong><br>{{ data.item.next }}</span>
                                </template>
                                 <template slot="manage" slot-scope="data">
                                    <span>
                                        <div>
                                            <button @click.prevent="updateToFree(data.item.id)" class="details-button details-button--default mt-0 mr-1 mb-1"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />To Free</button>
                                            <button @click.prevent="updateToStandard(data.item.id)" class="details-button details-button--green mt-0 mr-1 mb-1"><simple-line-icons icon="arrowUp" size="small" color="#504e70" />To Standard</button>
                                        </div>
                                        <div>
                                            <button @click.prevent="updateToPremium(data.item.id)" class="details-button details-button--green mt-0 mr-1 mb-1"><simple-line-icons icon="arrowUp" size="small" color="#504e70" />To Premium</button>
                                            <button @click.prevent="updateToUltimate(data.item.id)" class="details-button details-button--green mt-0 mr-1 mb-1"><simple-line-icons icon="arrowUp" size="small" color="#504e70" />To Ultimate</button>
                                        </div>
                                        <hr>
                                        <div>
                                            <button @click.prevent="subscriptionToActive(data.item.id)" class="details-button details-button--green mt-0 mr-1 mb-1"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Subscription to active</button>
                                            <button @click.prevent="subscriptionToPending(data.item.id)" class="details-button details-button--yellow mt-0 mr-1 mb-1"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Subscription to pending</button>
                                        </div>
                                        <div>
                                            <button @click.prevent="subscriptionToInactive(data.item.id)" class="details-button details-button--red mt-0 mr-1 mb-1"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Subscription to inactive</button>
                                            <button @click.prevent="subscriptionToCancelled(data.item.id)" class="details-button details-button--blue mt-0 mr-1 mb-1"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Subscription to cancelled</button>
                                        </div>
                                        <div>
                                            <button @click.prevent="subscriptionToNone(data.item.id)" class="details-button details-button--default mt-0 mr-1 mb-1"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Subscription to none</button>
                                        </div>
                                    </span>
                                </template>
                            </b-table>
                            <b-pagination
                                @change="scrollToTop('pendingUsers');"
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                                aria-controls="pendingUsers"
                            ></b-pagination>
                            <label class="mr-2" for="paginationInput">Go to page:</label><b-form-input class="pagination__input" id="paginationInput" v-model="currentPage"></b-form-input>
                        </div>
                    </div>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import AdminNavigation from '@/components/AdminNavigation'
import MoveTo from 'moveto'
export default {
  components: {
    SimpleLineIcons,
    Spinner,
    AdminNavigation
  },
  data: function () {
    return {
      perPage: 5,
      currentPage: '1',
      sortBy: 'id',
      sortDesc: true,
      filterUsers: '',
      fields: [
        { key: 'id', label: 'Uid', sortable: true },
        { key: 'sponsor', label: 'Sponsor', sortable: true },
        { key: 'sponsorType', label: 'Sponsor type', sortable: true },
        { key: 'subscriptionStatus', label: 'Subscription', sortable: true },
        { key: 'manage', label: 'Manage', sortable: false }
      ]
    }
  },
  methods: {
    clearFilter () {
      this.filterUsers = ''
    },
    updateToFree (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to update this user to Free?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('updateToFree', { id })
        }
      })
    },
    updateToStandard (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to update this user to Standard?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('updateToStandard', { id })
        }
      })
    },
    updateToPremium (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to update this user to Premium?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('updateToPremium', { id })
        }
      })
    },
    updateToUltimate (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to update this user to Ultimate?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('updateToUltimate', { id })
        }
      })
    },
    scrollToTop (element) {
      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById(element)

      moveTo.move(target)
    },
    subscriptionToActive (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: "Are you sure you want to update this user's subscription to active?",
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('subscriptionToActive', { id })
        }
      })
    },
    subscriptionToPending (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: "Are you sure you want to update this user's subscription to pending?",
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('subscriptionToPending', { id })
        }
      })
    },
    subscriptionToInactive (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: "Are you sure you want to update this user's subscription to inactive?",
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('subscriptionToInactive', { id })
        }
      })
    },
    subscriptionToCancelled (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: "Are you sure you want to update this user's subscription to cancelled?",
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('subscriptionToCancelled', { id })
        }
      })
    },
    subscriptionToNone (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: "Are you sure you want to update this user's subscription to none?",
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('subscriptionToNone', { id })
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'getUsersListPending',
      'getUsersList'
    ]),
    getSortedUsersList () {
      var sorted = this.getUsersList.map(function (user) {
        return {
          id: user.id,
          sponsor: (user.type != 1),
          sponsorType: user.type,
          subscriptionStatus: user.subscription,
          next: user.next,
          valid: user.valid
        }
      })

      return sorted
    },
    rows () {
      return this.getSortedUsersList.length
    }
  },
  beforeCreate () {
    this.$store.dispatch('getUsersList')
  }
}
</script>
